import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Container from "react-bootstrap/Container"

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        datamade_logo: file(relativePath: { eq: "datamade_logo.png" }) {
          childImageSharp {
            fixed(height: 20, width: 94) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        cnda_logo: file(relativePath: { eq: "cnda_logo.png" }) {
          childImageSharp {
            fixed(height: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <footer className="text-center" style={{
        backgroundColor: "#061a2c",
        color: "white",
        paddingBottom: "1rem"
      }}>
        <Container style={{minHeight: "200px"}}>
          <a href="http://lisc-cnda.org" style={{color: "white"}}>
            <Img
              fixed={data.cnda_logo.childImageSharp.fixed}
              alt="Chicago Neighborhood Development Awards logo"
              className="m-4"
            />
            <br />
          </a>
          <p style={{ whiteSpace: "normal", fontSize: "16px"}}>
            10 S. Riverside Plaza, Suite 1700 | Chicago, IL 60606<br />
            <a href="mailto:cnda@lisc.org">cnda@lisc.org</a>
          </p>
          <div>
            Website by<br />
            <a href="https://datamade.us">
              <Img
                fixed={data.datamade_logo.childImageSharp.fixed}
                alt="DataMade logo"
                className="mb-4 mt-2"
              />
            </a>
          </div>
        </Container>
      </footer>
    )}
  />
)

export default Footer
